import styled from "styled-components";
import {
    highlightBlue,
    highlightBrown,
    highlightDarkBlue,
    highlightGreen,
    highlightGrey,
    highlightOrange,
    highlightPink,
    highlightPurple,
    highlightRed,
    highlightYellow,
    backgroundColorLevelCurrent,
} from "@churchofjesuschrist/eden-style-constants";

export const highlightColors = {
    blue: highlightBlue,
    brown: highlightBrown,
    dark_blue: highlightDarkBlue,
    gray: highlightGrey,
    green: highlightGreen,
    orange: highlightOrange,
    pink: highlightPink,
    purple: highlightPurple,
    red: highlightRed,
    yellow: highlightYellow,
    clear: backgroundColorLevelCurrent,
};

export const Mark = styled.mark`
    background: ${({ clear, color: colorKey, underline }) => {
        const color = highlightColors[clear ? "clear" : colorKey];

        return underline || clear ? "transparent" : color;
    }};
    box-shadow: ${({ clear, color: colorKey, underline }) => {
        return underline
            ? `inset 0 -2px 0 var(--${clear ? "clear" : colorKey}Underline)`
            : "unset";
    }};
    color: inherit;
`;

export default Mark;
